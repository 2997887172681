<template>
  <div>
    <Header :title="!isSee ? (isEdit ? '修改公告' : '新增公告') : '查看公告'" @back="onBack(false)"></Header>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
              :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">
        <a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
          <a-select :disabled="isSee" placeholder="请选择所属影院" v-model:value="modelRef.cinemaId" allow-clear style="width: 200px;">
            <a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="公告标题" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
          <a-input :disabled="isSee" style="width: 200px;" v-model:value="modelRef.title" placeholder="请输入公告标题"></a-input>
        </a-form-item>

        <a-form-item label="是否启用" name="isDisabled">
          <a-switch :disabled="isSee" v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
        </a-form-item>

        <a-form-item name="content" :rules="[{ required: true, message: '必填项不允许为空' }]" label="公告内容">
          <a-textarea :disabled="isSee" :autosize="{ minRows: 6, maxRows: 99999 }" v-model:value="modelRef.content"></a-textarea>
        </a-form-item>

        <a-row>
          <a-col :span="24" style="margin-top: 20px;text-align: center;">
            <a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
            <a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
// import dayjs from 'dayjs';
import Header from '@/components/header/header.vue';
import { getCinemaAllList } from '@/service/modules/cinema.js';
import {
  getCinemaAnnouncementCenterDetail, saveCinemaAnnouncementCenter, updateCinemaAnnouncementCenter
} from "../../../../service/modules/operation";
export default {
  components: {
    Header,
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    isSee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      cinemaAllList: [],
      modelRef: {
        isDisabled: false,
      },
    }
  },
  created() {
    this.getAllCinemaList();
    if(this.id) {
      this.getData();
    }
  },
  methods: {
    onBack(isRef) {
      this.$emit('back', isRef);
    },
    async getAllCinemaList() {
      this.cinemaAllList = [];
      let ret = await getCinemaAllList({})
      if (ret.code === 200) {
        this.cinemaAllList = ret.data;
      }
    },
    async getData() {
      this.loading = true;
      try {
        let ret = await getCinemaAnnouncementCenterDetail({
          id: this.id
        })
        this.loading = false;
        if(ret.code === 200) {
          ret.data.isDisabled = ret.data.isDisabled ? false : true;

          this.modelRef = ret.data;
        }
      } catch(e) {
        this.loading = false;
      }
    },
    async onSubmit() {
      let postData = JSON.parse(JSON.stringify(this.modelRef));
      postData.isDisabled = postData.isDisabled ? 0 : 1;
      this.loading = true;
      try {
        let ret;
        if(this.isEdit) {
          postData.id = this.id;
          ret = await updateCinemaAnnouncementCenter(postData);
        } else {
          ret = await saveCinemaAnnouncementCenter(postData);
        }
        this.loading = false;
        if(ret.code === 200) {
          this.$message.success('操作成功');
          this.onBack(true);
        }
      } catch(e) {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>