import { send } from "../index";

export function getCinemaAnnouncementCenterList(data, opts = {}) {
    //  公告列表
    return send({
        url: "/admin/cinemaAnnouncementCenter/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getCinemaAnnouncementCenterDetail(data, opts = {}) {
    //  公告详情
    return send({
        url: "/admin/cinemaAnnouncementCenter/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveCinemaAnnouncementCenter(data, opts = {}) {
    //  新增公告
    return send({
        url: "/admin/cinemaAnnouncementCenter/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateCinemaAnnouncementCenter(data, opts = {}) {
    //  编辑公告
    return send({
        url: "/admin/cinemaAnnouncementCenter/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteCinemaAnnouncementCenter(data, opts = {}) {
    //  删除公告
    return send({
        url: "/admin/cinemaAnnouncementCenter/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformAdImageList(data, opts = {}) {
    //  广告图列表
    return send({
        url: "/admin/platformAdImage/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePlatformAdImage(data, opts = {}) {
    //  新增广告图
    return send({
        url: "/admin/platformAdImage/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePlatformAdImage(data, opts = {}) {
    //  编辑广告图
    return send({
        url: "/admin/platformAdImage/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePlatformAdImage(data, opts = {}) {
    //  广告图删除
    return send({
        url: "/admin/platformAdImage/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformAdImageDetail(data, opts = {}) {
    //  广告图详情
    return send({
        url: "/admin/platformAdImage/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformActivityList(data, opts = {}) {
    //  活动列表
    return send({
        url: "/admin/platformActivity/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePlatformActivity(data, opts = {}) {
    //  新增活动
    return send({
        url: "/admin/platformActivity/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePlatformActivity(data, opts = {}) {
    //  编辑活动
    return send({
        url: "/admin/platformActivity/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deletePlatformActivity(data, opts = {}) {
    //  活动删除
    return send({
        url: "/admin/platformActivity/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPlatformActivityDetail(data, opts = {}) {
    //  活动详情
    return send({
        url: "/admin/platformActivity/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAgreementSettingList(data, opts = {}) {
    //  协议列表
    return send({
        url: "/admin/agreementSetting/list.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getAgreementSettingDetail(data, opts = {}) {
    //  协议详情
    return send({
        url: "/admin/agreementSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateAgreementSetting(data, opts = {}) {
    //  协议编辑
    return send({
        url: "/admin/agreementSetting/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPointsSettingDetail(data, opts = {}) {
    //  积分设置详情
    return send({
        url: "/admin/pointsSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePointsSetting(data, opts = {}) {
    //  积分设置
    return send({
        url: "/admin/pointsSetting/update.do",
        method: "POST",
        data,
        ...opts,
    });
}
