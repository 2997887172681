import {send} from "../index";

export function getCinemaList(data, opts = {}) {
	//  影院列表
  return send({
    url: "/admin/cinema/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaSync(data, opts = {}) {
	//  影院同步
  return send({
    url: "/admin/cinema/sync.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaHallSync(data, opts = {}) {
	//  影厅同步
  return send({
    url: "/admin/cinema/cinemaHallSync.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function pullSchedules(data, opts = {}) {
	//  拉取影院排期
  return send({
    url: "/admin/cinemaSchedule/pullSchedules.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaDetail(data, opts = {}) {
	//  影院详情
  return send({
    url: "/admin/cinema/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getCinemaShareQR(data, opts = {}) {
    //  影院小程序码
  return send({
    url: "/admin/cinema/shareQR.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPayQR(data, opts = {}) {
    //  影院付款码
  return send({
    url: "/admin/cinema/payQR.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateCinema(data, opts = {}) {
	//  修改影院
  return send({
    url: "/admin/cinema/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveCinema(data, opts = {}) {
	//  添加影院
  return send({
    url: "/admin/cinema/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRefundPolicyList(data, opts = {}) {
    // 退票政策列表
  return send({
    url: "/admin/refundPolicy/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function saveRefundPolicy(data, opts = {}) {
    // 新增退票政策
  return send({
    url: "/admin/refundPolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateRefundPolicy(data, opts = {}) {
    // 修改退票政策
  return send({
    url: "/admin/refundPolicy/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deleteRefundPolicy(data, opts = {}) {
    // 删除退票政策
  return send({
    url: "/admin/refundPolicy/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getRefundPolicyDetail(data, opts = {}) {
    // 退票政策详情
  return send({
    url: "/admin/refundPolicy/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPricePolicyList(data, opts = {}) {
    // 定价政策列表
  return send({
    url: "/admin/pricePolicy/searchList.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function savePricePolicy(data, opts = {}) {
	// 新增定价政策
  return send({
    url: "/admin/pricePolicy/save.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updatePricePolicy(data, opts = {}) {
	// 修改定价政策
  return send({
    url: "/admin/pricePolicy/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function deletePricePolicy(data, opts = {}) {
	// 删除定价政策
  return send({
    url: "/admin/pricePolicy/delete.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getPricePolicyDetail(data, opts = {}) {
	// 定价政策详情
  return send({
    url: "/admin/pricePolicy/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function syncPricing(data, opts = {}) {
	// 同步定价政策
  return send({
    url: "/admin/pricePolicy/syncPricing.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFilmList(data, opts = {}) {
  // 电影列表
  return send({
    url: "/admin/filmInfo/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFilmInfoList(data, opts = {}) {
	// 影片列表
  return send({
    url: "/admin/cinemaFilm/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getFilmInfoDetail(data, opts = {}) {
    // 影片详情
  return send({
    url: "/admin/filmInfo/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateFilmInfo(data, opts = {}) {
    // 影片更新
  return send({
    url: "/admin/filmInfo/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserFilmCommentList(data, opts = {}) {
    // 影片评价列表
  return send({
    url: "/admin/userFilmComment/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateUserFilmComment(data, opts = {}) {
  // 影片评价更新
  return send({
    url: "/admin/userFilmComment/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getUserFilmCommentDetail(data, opts = {}) {
  // 影片评价详情
  return send({
    url: "/admin/filmInfo/detail.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateFilmImg(data, opts = {}) {
	// 影片海报修改
  return send({
    url: "/admin/cinema/updateFilmImg.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getHallList(data, opts = {}) {
	// 影厅列表
  return send({
    url: "/admin/cinemaHall/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function updateHall(data, opts = {}) {
  // 影厅更新
  return send({
    url: "/admin/cinemaHall/update.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getScheduleList(data, opts = {}) {
	// 场次列表
  return send({
    url: "/admin/cinemaSchedule/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAreaList(data, opts = {}) {
	// 场区列表
  return send({
    url: "/admin/cinemaArea/list.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function cinemaScheduleSync(data, opts = {}) {
    // 排期同步
  return send({
    url: "/admin/cinema/cinemaScheduleSync.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getCinemaTagList(data, opts = {}) {
  // 影院标签列表
  return send({
    url: "/admin/cinemaTag/list.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function saveCinemaTag(data, opts = {}) {
  // 添加影院标签
  return send({
    url: "/admin/cinemaTag/save.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function updateCinemaTag(data, opts = {}) {
  // 修改影院标签
  return send({
    url: "/admin/cinemaTag/update.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function deleteCinemaTag(data, opts = {}) {
  // 删除影院标签
  return send({
    url: "/admin/cinemaTag/delete.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getCinemaTagDetail(data, opts = {}) {
  // 影院标签详情
  return send({
    url: "/admin/cinemaTag/detail.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getCinemaTagAllList(data, opts = {}) {
  // 影院标签详情
  return send({
    url: "/admin/cinemaTag/getAll.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getAdminUserCinemaList(data, opts = {}) {
    // 关联用户列表
  return send({
    url: "/admin/adminUserCinema/list.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function saveAdminUserCinema(data, opts = {}) {
    // 添加关联用户
  return send({
    url: "/admin/adminUserCinema/save.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function deleteAdminUserCinema(data, opts = {}) {
    // 删除关联用户
  return send({
    url: "/admin/adminUserCinema/delete.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getBaseCitySortList(data, opts = {}) {
  // 城市配置列表
  return send({
    url: "/admin/baseCitySort/list.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function saveBaseCitySort(data, opts = {}) {
  // 添加城市配置
  return send({
    url: "/admin/baseCitySort/save.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function deleteBaseCitySort(data, opts = {}) {
  // 删除城市配置
  return send({
    url: "/admin/baseCitySort/delete.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function updateBaseCitySort(data, opts = {}) {
  // 修改城市配置
  return send({
    url: "/admin/baseCitySort/update.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getBaseAreaSortAllList(data, opts = {}) {
// 获取全部区域列表
  return send({
    url: "/admin/baseAreaSort/getAll.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getCinemaLocationList(data, opts = {}) {
    // 影院位置列表
  return send({
    url: "/admin/cinemaLocation/list.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function saveCinemaLocation(data, opts = {}) {
    // 添加影院位置
  return send({
    url: "/admin/cinemaLocation/save.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function updateCinemaLocation(data, opts = {}) {
    // 更新影院位置
  return send({
    url: "/admin/cinemaLocation/update.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getBaseUndergroundList(data, opts = {}) {
  // 地铁线路配置列表
  return send({
    url: "/admin/baseUnderground/list.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getBaseUndergroundGetAll(data, opts = {}) {
  // 地铁线路配置全部列表
  return send({
    url: "/admin/baseUnderground/getAll.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function saveBaseUnderground(data, opts = {}) {
  // 添加地铁线路配置
  return send({
    url: "/admin/baseUnderground/save.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function updateBaseUnderground(data, opts = {}) {
  // 修改地铁线路配置
  return send({
    url: "/admin/baseUnderground/update.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function deleteBaseUnderground(data, opts = {}) {
  // 删除地铁线路配置
  return send({
    url: "/admin/baseUnderground/delete.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getDistrictChildren(data, opts = {}) {
  // 获取行政区域列表
    return send({
      url: "/admin/district/getChildren.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function addBaseAreaSort(data, opts = {}) {
  // 新增区域配置
    return send({
      url: "/admin/baseAreaSort/save.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function getBaseAreaSortList(data, opts = {}) {
  // 获取区域配置列表
    return send({
      url: "/admin/baseAreaSort/list.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function updateBaseAreaSort(data, opts = {}) {
  // 修改区域配置
    return send({
      url: "/admin/baseAreaSort/update.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function deleteBaseAreaSort(data, opts = {}) {
  // 删除区域配置
    return send({
      url: "/admin/baseAreaSort/delete.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function getBaseBusinessCircle(data, opts = {}) {
  // 获取商圈配置列表
    return send({
      url: "/admin/baseBusinessCircle/list.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function addBaseBusinessCircle(data, opts = {}) {
  // 新增商圈配置
    return send({
      url: "/admin/baseBusinessCircle/save.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function updateBaseBusinessCircle(data, opts = {}) {
  // 修改商圈配置
    return send({
      url: "/admin/baseBusinessCircle/update.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function deleteBaseBusinessCircle(data, opts = {}) {
  // 修改商圈配置
    return send({
      url: "/admin/baseBusinessCircle/delete.do",
      method: 'POST',
      data,
      ...opts
    });
}

export function getBaseUndergroundSiteList(data, opts = {}) {
  // 地铁站点配置列表
  return send({
    url: "/admin/baseUndergroundSite/list.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function saveBaseUndergroundSite(data, opts = {}) {
  // 添加地铁站点配置
  return send({
    url: "/admin/baseUndergroundSite/save.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function updateBaseUndergroundSite(data, opts = {}) {
  // 修改地铁站点配置
  return send({
    url: "/admin/baseUndergroundSite/update.do",
    method: 'POST',
    data,
    ...opts
  });
}


export function deleteBaseUndergroundSite(data, opts = {}) {
  // 删除地铁站点配置
  return send({
    url: "/admin/baseUndergroundSite/delete.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getCinemaServiceApplyList(data, opts = {}) {
  // 影院服务列表
  return send({
    url: "/admin/cinemaServiceApply/list.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function updateCinemaServiceApply(data, opts = {}) {
  // 修改影院服务
  return send({
    url: "/admin/cinemaServiceApply/update.do",
    method: 'POST',
    data,
    ...opts
  });
}

export function getCinemaAllList(data, opts = {}) {
	//  获取全部影院
  return send({
    url: "/admin/cinema/getAll.do",
    method: "POST",
    data,
    ...opts,
  });
}

export function getAllCinemaPartitionList(data, opts = {}) {
  //  获取全部影院分区
  return send({
    url: "/admin/cinemaPartition/getAll.do",
    method: "POST",
    data,
    ...opts,
  });
}